
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "CMStatus",
  props: {
    status: {type: String, required: true},
  },
  setup(props) {
    const data = ref({clazz: '', name: ''})

    const buildStatus = (status) => {
      switch (status) {
        case 'NOT_SET':
          data.value = {clazz: 'success', name: ''}
          break
        case 'ACTIVE' :
          data.value = {clazz: 'success', name: 'Active'}
          break
        case 'CLOSED' :
          data.value = {clazz: 'metal', name: 'Closed'}
          break
        case 'NOT_REQUIRED' :
          data.value = {clazz: 'warning', name: 'Not required'}
          break
      }
    }
    watch(() => props.status, (cb) => {
      buildStatus(cb)
    })
    buildStatus(props.status)
    return {
      data
    }
  }
})
