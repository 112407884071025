
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {computed, defineComponent, watch} from "vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "LatestReview",
  components: {DateTimeFormat, Avatar},
  props: {
    cazeId: {type: String, required: true},
  },
  setup(props) {
    const review = computed(() => store.state.ActivityModule.latestReview);
    const load = (id) => {
      store.dispatch(Actions.ACTIVITY_LATEST_REVIEWS, id);
    }
    load(props.cazeId)
    watch(() => props.cazeId, cb => {
      load(cb)
    })
    return {
      review,
    }
  }
})
