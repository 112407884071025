
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "Priority",
  props: {priority:{}},
  setup(props) {
    const data = ref()

    const buildStatus = (status) => {
      switch (status) {
        case 'LOW':
          data.value = 'Low'
          break
        case 'MEDIUM' :
          data.value = 'Medium'
          break
        case 'HIGH' :
          data.value = 'High'
          break
        case 'HIGHEST' :
          data.value = 'highest'
          break
      }
    }
    watch(() => props.priority, (cb) => {
      buildStatus(cb)
    })
    buildStatus(props.priority)
    return {
      data,
    }
  }
})
