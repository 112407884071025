
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {CaseManagerSelect} from "@/core/composite/composite";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {CM_STATUS, PRIORITY} from "@/core/config/Constant";
import CaseService from "@/core/services/CaseService";

export default defineComponent({
  name: "CaseManagementForm",
  components: {BaseRadio, BaseSelect, BaseForm},
  props: {
    cazeId: {type:String, required:true},
    data: {}
  },
  setup(props) {
    const model = ref(props.data)
    const submitting = ref(false);
    watch(() => props.data, cb => {
      model.value = cb;
    })
    return {
      model,
      submitting,
      CM_STATUS,
      PRIORITY,
      ...CaseManagerSelect(),
    }
  },
  emits:['updated'],
  methods: {
    onSubmit() {
      this.submitting = true
      CaseService.cmUpdate(this.cazeId, this.model).then(res=>{
        this.$emit('updated', res)
      }).finally(()=>{
        this.submitting = false;
      })
    }
  }
})
