
import {computed, defineComponent} from "vue";
import store from "../../store";
import CMStatus from "@/views/case/CMStatus.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import {Mutations} from "@/store/enums/StoreEnums";
import Row from "@/components/base/common/Row.vue";
import Priority from "@/components/base/Priority.vue";
import CaseManagementForm from "@/views/case/CaseManagementForm.vue";
import LatestReview from "@/views/case/LatestReview.vue";
import CheckList from "@/views/checklist/CheckList.vue";
import Avatar from "@/components/base/common/Avatar.vue";

export default defineComponent({
  name: "ManagementOverview",
  components: {
    Avatar,
    CheckList, LatestReview, CaseManagementForm, BaseModal,  Priority, Row, QuickAction, CMStatus},
  setup() {
    const caze = computed<any>(() => store.state.CaseModule.case);
    return {
      caze,
    }
  },
  methods: {
    onUpdate() {
      const modal = this.$refs.caseManagementRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onUpdated(caze) {
      store.commit(Mutations.SET_CASE, caze);
      if (caze.cmStatus === 'NOT_REQUIRED') {
        this.$router.push({
          name: 'caseOverview',
          params: {id: caze.id}
        })
      }
      const modal = this.$refs.caseManagementRef as typeof BaseModal;
      modal.hideBaseModal();
    }
  }
})
